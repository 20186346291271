import {Temporal} from '@js-temporal/polyfill';
const {Instant} = Temporal;

import {adjust, invertObj, sortBy, zipObj} from 'ramda';
import {arrayToOptions} from '../Util';
import {PriceTier} from './Constants';
import {isProduction} from './EnvVar';
import {GeoJsonFeature} from './Geojson';
import {standardizePhone} from './Sms';
import {
  Timestamp,
  TimestampInterval,
  Timezone,
  addHours,
  convertTimestampToTimezone,
  differenceInDays,
  getHours,
  isoNow,
  replaceTimezone,
  startOfToday,
} from './Time';

export enum Market {
  BAY_AREA = 'Bay Area',
  PHOENIX = 'Phoenix',
  MIAMI = 'Miami',
  SACRAMENTO = 'Sacramento',
  LOS_ANGELES = 'Los Angeles',
}
export const ACTIVE_MARKETS = [
  Market.BAY_AREA,
  Market.PHOENIX,
  Market.SACRAMENTO,
  Market.LOS_ANGELES,
] as const;
export const ACTIVE_STORE_MARKETS = [Market.BAY_AREA, Market.PHOENIX] as const;
export type ActiveStoreMarket = (typeof ACTIVE_STORE_MARKETS)[number];
export type ActiveMarket = (typeof ACTIVE_MARKETS)[number];
export type MarketMap<V> = Record<(typeof Market)[keyof typeof Market], V>;
export type ActiveMarketMap<V> = Record<ActiveMarket, V>;
export type ActiveStoreMarketMap<V> = Record<ActiveStoreMarket, V>;
export const MARKET_TAG_MAP: Record<string, ActiveMarket> = {
  bayarea: Market.BAY_AREA,
  phoenix: Market.PHOENIX,
  sacramento: Market.SACRAMENTO,
  losangeles: Market.LOS_ANGELES,
};
export type MarketTag = keyof typeof MARKET_TAG_MAP;
export type MarketSearch = {market?: Maybe<OrArray<string>>};
export type DBMarket = {
  id: number;
  name: Market;
  managerId: Maybe<number>;
  storageCostPerSquareFeet?: Maybe<number>;
};
export type PickupSizeType = {
  description: string;
  '0/1': 0;
  '1/8': number;
  '1/4': number;
  '3/8': number;
  '1/2': number;
  '5/8': number;
  '3/4': number;
  '7/8': number;
  Full: number;
};
export type ProgressiveFeeTable = [string, number][];
export type PickupPriceType = {
  [P in PriceTier]: PickupSizeType;
} & {
  donation: ProgressiveFeeTable;
  recycle: ProgressiveFeeTable;
  disassemblyCostPerHour: number;
  packingPricePerHour: number;
  packingPricePerCubicFeet: number;
  stairsFee: number;
  elevatorNotReservedFee: number;
  parkingFee: number;
};
// Exchange to "satisfies" when typescript update version (make sure throws error if not complete)
export const MarketsPickupPrices: MarketMap<PickupPriceType> = {
  [Market.BAY_AREA]: {
    local: {
      description: 'In-Area',
      '0/1': 0,
      '1/8': 209,
      '1/4': 279,
      '3/8': 349,
      '1/2': 409,
      '5/8': 479,
      '3/4': 539,
      '7/8': 589,
      Full: 649,
    },
    extended: {
      description: 'Extended',
      '0/1': 0,
      '1/8': 259,
      '1/4': 329,
      '3/8': 399,
      '1/2': 459,
      '5/8': 529,
      '3/4': 599,
      '7/8': 659,
      Full: 719,
    },
    double: {
      description: 'Double',
      '0/1': 0,
      '1/8': 309,
      '1/4': 379,
      '3/8': 449,
      '1/2': 509,
      '5/8': 579,
      '3/4': 649,
      '7/8': 709,
      Full: 769,
    },
    out_of_area: {
      description: 'Out of Area',
      '0/1': 0,
      '1/8': 309,
      '1/4': 379,
      '3/8': 449,
      '1/2': 509,
      '5/8': 579,
      '3/4': 649,
      '7/8': 709,
      Full: 769,
    },
    donation: [
      ['1/8', 0.4],
      ['1/4', 0.4],
      ['3/8', 0.4],
      ['1/2', 0.3],
      ['5/8', 0.2],
      ['3/4', 0.2],
      ['7/8', 0.2],
      ['1', 0.2],
      ['1 1/8', 0.2],
      ['1 1/4+', 0.15],
    ],
    recycle: [
      ['1/8', 1.6],
      ['1/4', 1.6],
      ['3/8', 1.4],
      ['1/2', 1.2],
      ['5/8', 1.2],
      ['3/4', 1.2],
      ['7/8', 1.1],
      ['1', 1.1],
      ['1 1/8+', 1],
    ],
    disassemblyCostPerHour: 75,
    packingPricePerHour: 35,
    packingPricePerCubicFeet: 0.6,
    stairsFee: 25,
    parkingFee: 20,
    elevatorNotReservedFee: 15,
  },
  [Market.PHOENIX]: {
    local: {
      description: 'In-Area',
      '0/1': 0,
      '1/8': 169,
      '1/4': 209,
      '3/8': 269,
      '1/2': 329,
      '5/8': 369,
      '3/4': 419,
      '7/8': 479,
      Full: 529,
    },
    extended: {
      description: 'Extended',
      '0/1': 0,
      '1/8': 199,
      '1/4': 239,
      '3/8': 299,
      '1/2': 359,
      '5/8': 399,
      '3/4': 449,
      '7/8': 509,
      Full: 559,
    },
    double: {
      description: 'Double',
      '0/1': 0,
      '1/8': 239,
      '1/4': 279,
      '3/8': 339,
      '1/2': 399,
      '5/8': 439,
      '3/4': 489,
      '7/8': 549,
      Full: 599,
    },
    out_of_area: {
      description: 'Out of Area',
      '0/1': 0,
      '1/8': 239,
      '1/4': 279,
      '3/8': 339,
      '1/2': 399,
      '5/8': 439,
      '3/4': 489,
      '7/8': 549,
      Full: 599,
    },
    donation: [
      ['1/8', 0.3],
      ['1/4', 0.3],
      ['3/8', 0.225],
      ['1/2', 0.225],
      ['5/8', 0.225],
      ['3/4', 0.225],
      ['7/8', 0.15],
      ['1+', 0.15],
    ],
    recycle: [
      ['0+', 0.65],
      ['1/8', 0.65],
      ['1/4', 0.65],
      ['3/8', 0.65],
      ['1/2', 0.65],
      ['5/8', 0.65],
      ['3/4', 0.65],
      ['7/8', 0.65],
      ['1+', 0.65],
    ],
    disassemblyCostPerHour: 75,
    packingPricePerHour: 35,
    packingPricePerCubicFeet: 0.6,
    stairsFee: 25,
    parkingFee: 20,
    elevatorNotReservedFee: 15,
  },
  [Market.MIAMI]: {
    local: {
      description: 'In-Area',
      '0/1': 0,
      '1/8': 169,
      '1/4': 209,
      '3/8': 269,
      '1/2': 329,
      '5/8': 369,
      '3/4': 419,
      '7/8': 479,
      Full: 529,
    },
    extended: {
      description: 'Extended',
      '0/1': 0,
      '1/8': 199,
      '1/4': 239,
      '3/8': 299,
      '1/2': 359,
      '5/8': 399,
      '3/4': 449,
      '7/8': 509,
      Full: 559,
    },
    double: {
      description: 'Double',
      '0/1': 0,
      '1/8': 239,
      '1/4': 279,
      '3/8': 339,
      '1/2': 399,
      '5/8': 439,
      '3/4': 489,
      '7/8': 549,
      Full: 599,
    },
    out_of_area: {
      description: 'Out of Area',
      '0/1': 0,
      '1/8': 239,
      '1/4': 279,
      '3/8': 339,
      '1/2': 399,
      '5/8': 439,
      '3/4': 489,
      '7/8': 549,
      Full: 599,
    },
    donation: [
      ['0+', 0],
      ['1/8', 0],
      ['1/4', 0],
      ['3/8', 0],
      ['1/2', 0],
      ['5/8', 0],
      ['3/4', 0],
      ['7/8', 0],
      ['1+', 0],
    ],
    recycle: [
      ['0+', 0.65],
      ['1/8', 0.65],
      ['1/4', 0.65],
      ['3/8', 0.65],
      ['1/2', 0.65],
      ['5/8', 0.65],
      ['3/4', 0.65],
      ['7/8', 0.65],
      ['1+', 0.65],
    ],
    disassemblyCostPerHour: 40,
    packingPricePerHour: 35,
    packingPricePerCubicFeet: 0.6,
    stairsFee: 25,
    parkingFee: 20,
    elevatorNotReservedFee: 15,
  },
  [Market.SACRAMENTO]: {
    local: {
      description: 'In-Area',
      '0/1': 0,
      '1/8': 169,
      '1/4': 209,
      '3/8': 269,
      '1/2': 329,
      '5/8': 369,
      '3/4': 419,
      '7/8': 479,
      Full: 529,
    },
    extended: {
      description: 'Extended',
      '0/1': 0,
      '1/8': 199,
      '1/4': 239,
      '3/8': 299,
      '1/2': 359,
      '5/8': 399,
      '3/4': 449,
      '7/8': 509,
      Full: 559,
    },
    double: {
      description: 'Double',
      '0/1': 0,
      '1/8': 239,
      '1/4': 279,
      '3/8': 339,
      '1/2': 399,
      '5/8': 439,
      '3/4': 489,
      '7/8': 549,
      Full: 599,
    },
    out_of_area: {
      description: 'Out of Area',
      '0/1': 0,
      '1/8': 239,
      '1/4': 279,
      '3/8': 339,
      '1/2': 399,
      '5/8': 439,
      '3/4': 489,
      '7/8': 549,
      Full: 599,
    },
    donation: [
      ['1/8', 0.3],
      ['1/4', 0.3],
      ['3/8', 0.225],
      ['1/2', 0.225],
      ['5/8', 0.225],
      ['3/4', 0.225],
      ['7/8', 0.15],
      ['1+', 0.15],
    ],
    recycle: [
      ['0+', 0.65],
      ['1/8', 0.65],
      ['1/4', 0.65],
      ['3/8', 0.65],
      ['1/2', 0.65],
      ['5/8', 0.65],
      ['3/4', 0.65],
      ['7/8', 0.65],
      ['1+', 0.65],
    ],
    disassemblyCostPerHour: 75,
    packingPricePerHour: 35,
    packingPricePerCubicFeet: 0.6,
    stairsFee: 25,
    parkingFee: 20,
    elevatorNotReservedFee: 15,
  },
  [Market.LOS_ANGELES]: {
    local: {
      description: 'In-Area',
      '0/1': 0,
      '1/8': 199,
      '1/4': 259,
      '3/8': 329,
      '1/2': 389,
      '5/8': 459,
      '3/4': 519,
      '7/8': 559,
      Full: 619,
    },
    extended: {
      description: 'Extended',
      '0/1': 0,
      '1/8': 199 + 50,
      '1/4': 259 + 50,
      '3/8': 329 + 50,
      '1/2': 389 + 50,
      '5/8': 459 + 50,
      '3/4': 519 + 50,
      '7/8': 559 + 50,
      Full: 619 + 50,
    },
    double: {
      description: 'Double',
      '0/1': 0,
      '1/8': 199 + 100,
      '1/4': 259 + 100,
      '3/8': 329 + 100,
      '1/2': 389 + 100,
      '5/8': 459 + 100,
      '3/4': 519 + 100,
      '7/8': 559 + 100,
      Full: 619 + 100,
    },
    out_of_area: {
      description: 'Out of Area',
      '0/1': 0,
      '1/8': 319,
      '1/4': 389,
      '3/8': 459,
      '1/2': 529,
      '5/8': 599,
      '3/4': 659,
      '7/8': 709,
      Full: 779,
    },
    donation: [
      ['1/8', 0.6],
      ['1/4', 0.6],
      ['3/8', 0.5],
      ['1/2', 0.3],
      ['5/8', 0.3],
      ['3/4', 0.3],
      ['7/8', 0.15],
      ['1', 0.15],
      ['1 1/8', 0.15],
      ['1 1/4+', 0.15],
    ],
    recycle: [
      ['1/8', 1.4],
      ['1/4', 1.4],
      ['3/8', 1.2],
      ['1/2', 1.0],
      ['5/8', 1.0],
      ['3/4', 1.0],
      ['7/8', 1.0],
      ['1', 1.0],
      ['1 1/8+', 0.85],
    ],
    disassemblyCostPerHour: 75,
    packingPricePerHour: 35,
    packingPricePerCubicFeet: 0.6,
    stairsFee: 25,
    parkingFee: 20,
    elevatorNotReservedFee: 15,
  },
};
export const LOST_FOUND_PICKUP_ID: MarketMap<number> = {
  [Market.BAY_AREA]: 372,
  [Market.PHOENIX]: 27007,
  [Market.MIAMI]: 76179,
  [Market.SACRAMENTO]: 149575,
  [Market.LOS_ANGELES]: 167503,
};
export const LOST_FOUND_OWNER_ID: MarketMap<number> = {
  [Market.BAY_AREA]: 18374,
  [Market.PHOENIX]: 25199,
  [Market.MIAMI]: 70648,
  [Market.SACRAMENTO]: 137425,
  [Market.LOS_ANGELES]: 153328,
};
export const REMOOV_PICKUP_ID = 0;
export const REMOOV_PHX_PICKUP_ID = 54332;
export const REMOOV_MIA_PICKUP_ID = 86223;
export const REMOOV_SACRAMENTO_PICKUP_ID = 149575;
export const REMOOV_LOSANGELES_PICKUP_ID = 167503;
export const MARKET_TO_REMOOV_PICKUP_ID: MarketMap<number> = {
  [Market.BAY_AREA]: REMOOV_PICKUP_ID,
  [Market.PHOENIX]: REMOOV_PHX_PICKUP_ID,
  [Market.MIAMI]: REMOOV_MIA_PICKUP_ID,
  [Market.SACRAMENTO]: REMOOV_SACRAMENTO_PICKUP_ID,
  [Market.LOS_ANGELES]: REMOOV_LOSANGELES_PICKUP_ID,
};
export const REMOOV_PICKUP_IDS = Object.values(MARKET_TO_REMOOV_PICKUP_ID);
export const MARKETS = Object.values(Market);
export const DEFAULT_MARKET: Market & ActiveMarket & ActiveStoreMarket = Market.BAY_AREA;
export const MARKET_OPTIONS = arrayToOptions(MARKETS);
export const ACTIVE_MARKET_OPTIONS = arrayToOptions(ACTIVE_MARKETS);
const ipRegionMap: Record<string, Market> = MARKETS.reduce(
  (acc, market) => ({...acc, [getMarketState(market)]: market}),
  {},
);
export function isValidMarket(market: Maybe<string>): market is Market {
  return !!market && MARKETS.includes(market);
}
export function isActiveStoreMarket(market: string): market is ActiveStoreMarket {
  // @ts-expect-error Type Guard Function to narrow the input type
  return isValidMarket(market) && ACTIVE_STORE_MARKETS.includes(market);
}
function validMarketOrDefault(market: Maybe<string>): Market {
  return isValidMarket(market) ? market : DEFAULT_MARKET;
}
export function getMarketTextPhoneAddress(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = {
    [Market.BAY_AREA]: '415-969-4060',
    [Market.PHOENIX]: '602-609-6655',
    [Market.MIAMI]: '786-705-4060',
    [Market.SACRAMENTO]: '916-250-2332',
    [Market.LOS_ANGELES]: '424-380-5568',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketTelephone(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = {
    [Market.BAY_AREA]: '415-857-2791',
    [Market.PHOENIX]: '480-542-0442',
    [Market.MIAMI]: '305-317-5121',
    [Market.SACRAMENTO]: '916-250-1403',
    [Market.LOS_ANGELES]: '310-697-5965',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketReperchSupportPhone(market: Maybe<string>): string {
  const marketMap: ActiveStoreMarketMap<string> = {
    [Market.BAY_AREA]: '650-727-5864',
    [Market.PHOENIX]: '480-660-6957',
  };
  const m = market || DEFAULT_MARKET;
  return marketMap[isActiveStoreMarket(m) ? m : DEFAULT_MARKET];
}
export function getMarketADSTextPhone(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = {
    [Market.BAY_AREA]: '415-969-4075',
    [Market.PHOENIX]: '602-609-6470',
    [Market.MIAMI]: '786-705-4065',
    [Market.SACRAMENTO]: '916-250-2332',
    [Market.LOS_ANGELES]: '424-380-5568',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function phoneNumberToMarket(phoneNumber: string): Market {
  return (
    MARKETS.find(m =>
      [getMarketTextPhoneAddress(m), getMarketADSTextPhone(m)].includes(
        standardizePhone(phoneNumber),
      ),
    ) || DEFAULT_MARKET
  );
}
export function redirectPhoneNumber(phone: string): string {
  const phoneMarket = phoneNumberToMarket(phone) || DEFAULT_MARKET;
  return getMarketTelephone(phoneMarket);
}
export function getMarketFormalAddress(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = {
    [Market.BAY_AREA]: '2415 17th St, San Francisco, CA 94110',
    [Market.PHOENIX]: '1015 N 21st Ave, Phoenix, AZ 85009',
    [Market.MIAMI]: '7457 NW 55th St, Miami, FL 33166',
    [Market.SACRAMENTO]: '5770 Auburn Blvd, Sacramento, CA 95841',
    [Market.LOS_ANGELES]: '1045 Santee St, Los Angeles, CA 90015',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketAddress(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = {
    [Market.BAY_AREA]: '2415 17th St, San Francisco',
    [Market.PHOENIX]: '1015 N 21st Ave, Phoenix',
    [Market.MIAMI]: '7457 NW 55th St, Miami',
    [Market.SACRAMENTO]: '5770 Auburn Blvd, Sacramento',
    [Market.LOS_ANGELES]: '1045 Santee St, Los Angeles',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketZipcode(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = {
    [Market.BAY_AREA]: '94110',
    [Market.PHOENIX]: '85009',
    [Market.MIAMI]: '33166',
    [Market.SACRAMENTO]: '95841',
    [Market.LOS_ANGELES]: '90015',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketCity(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = {
    [Market.BAY_AREA]: 'San Francisco',
    [Market.PHOENIX]: 'Phoenix',
    [Market.MIAMI]: 'Miami',
    [Market.SACRAMENTO]: 'Sacramento',
    [Market.LOS_ANGELES]: 'Los Angeles',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketState(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = {
    [Market.BAY_AREA]: 'California',
    [Market.PHOENIX]: 'Arizona',
    [Market.MIAMI]: 'Florida',
    [Market.SACRAMENTO]: 'California',
    [Market.LOS_ANGELES]: 'California',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketStateCode(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = {
    [Market.BAY_AREA]: 'CA',
    [Market.PHOENIX]: 'AZ',
    [Market.MIAMI]: 'FL',
    [Market.SACRAMENTO]: 'CA',
    [Market.LOS_ANGELES]: 'CA',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function marketToEmailPrefix(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = {
    [Market.BAY_AREA]: 'SF',
    [Market.PHOENIX]: 'Phoenix',
    [Market.MIAMI]: 'Miami',
    [Market.SACRAMENTO]: 'Sacramento',
    [Market.LOS_ANGELES]: 'Los Angeles',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function marketToTimezone(market: Maybe<string>) {
  const marketMap: MarketMap<string> = {
    [Market.BAY_AREA]: 'America/Los_Angeles',
    [Market.PHOENIX]: 'America/Phoenix',
    [Market.MIAMI]: 'America/New_York',
    [Market.SACRAMENTO]: 'America/Los_Angeles',
    [Market.LOS_ANGELES]: 'America/Los_Angeles',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function marketToItemPriceDiscount(market: Maybe<string>): number {
  const marketMap: MarketMap<number> = {
    [Market.BAY_AREA]: 1,
    [Market.PHOENIX]: 0.85,
    [Market.MIAMI]: 1,
    [Market.SACRAMENTO]: 1,
    [Market.LOS_ANGELES]: 1,
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketPickupCalendarId(market: Maybe<string>): string {
  if (!isProduction()) return 'remoovit.com_fc616p776sv70buk3dcdatk7m8@group.calendar.google.com';
  const marketMap: MarketMap<string> = {
    [Market.BAY_AREA]: 'remoovit.com_mllmcndccmu8ai4u6k1kc6k4go@group.calendar.google.com',
    [Market.PHOENIX]: 'remoovit.com_r402i7ig3881lqhgpbbhhpus9s@group.calendar.google.com',
    [Market.MIAMI]: 'c_8jjfb4muf3iis1b4lsvv2c6vjo@group.calendar.google.com',
    [Market.SACRAMENTO]:
      'c_a547f803f1e0084c8cddc63742cb150aabd83e51c64693d6d8755ec54451940a@group.calendar.google.com',
    [Market.LOS_ANGELES]:
      'c_9b509941fde4ab223ff95309e7a89a3334ef6c8d9d25bbd036305b2020b0c237@group.calendar.google.com',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketConsultationCalendarId(market: Maybe<string>): string {
  if (!isProduction()) return 'remoovit.com_fc616p776sv70buk3dcdatk7m8@group.calendar.google.com';
  const marketMap: MarketMap<string> = {
    [Market.BAY_AREA]: 'remoovit.com_jr80q3hfjohdv5glc6uktfsbsk@group.calendar.google.com',
    [Market.PHOENIX]: 'remoovit.com_cclad586m9dsvef1femu4ihicc@group.calendar.google.com',
    [Market.MIAMI]: 'c_0g2emu6l0tdb989plqakh8smq8@group.calendar.google.com',
    [Market.SACRAMENTO]:
      'c_973b758ade0ba60924de6262d65a3ffcb0aeac0344725502b19d53f7bd60f491@group.calendar.google.com',
    [Market.LOS_ANGELES]:
      'c_f9d534d9ac78109a73e10cd906eaece716f4b5ef1ef19f9e9689e5ae3081899b@group.calendar.google.com',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketDeliveryCalendarId(market: Maybe<string>): string {
  if (!isProduction()) return 'remoovit.com_fc616p776sv70buk3dcdatk7m8@group.calendar.google.com';
  const marketMap: MarketMap<string> = {
    [Market.BAY_AREA]:
      'c_1355362c1b455d7cdccf143170588a5f201df257df42cdda60df663882d6de79@group.calendar.google.com',
    [Market.PHOENIX]:
      'c_7b9eb59ae9567c6c8762058e90a5c1eacc69647d2894a507d0f927ee996885c2@group.calendar.google.com',
    [Market.MIAMI]:
      'c_d319a1bf1aedbe9c53fc642f49652e2c6ff7c8e01768a1f98fa44963b09e5b82@group.calendar.google.com',
    [Market.SACRAMENTO]:
      'c_c140a6a2eca725cf0101ecb778b1874dc0eef3daec4e188600950930e81efbce@group.calendar.google.com',
    [Market.LOS_ANGELES]:
      'c_708044227446eb019822019574df26d88b7a06e0cb1ec2f8bc20f733e7deb235@group.calendar.google.com',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketVideoConsultationCalendarId(market: Maybe<string>): string {
  if (!isProduction()) return 'remoovit.com_fc616p776sv70buk3dcdatk7m8@group.calendar.google.com';
  const marketMap: MarketMap<string> = {
    [Market.BAY_AREA]: 'remoovit.com_jr80q3hfjohdv5glc6uktfsbsk@group.calendar.google.com',
    [Market.PHOENIX]: 'remoovit.com_cclad586m9dsvef1femu4ihicc@group.calendar.google.com',
    [Market.MIAMI]: 'c_0g2emu6l0tdb989plqakh8smq8@group.calendar.google.com',
    [Market.SACRAMENTO]:
      'c_973b758ade0ba60924de6262d65a3ffcb0aeac0344725502b19d53f7bd60f491@group.calendar.google.com',
    [Market.LOS_ANGELES]:
      'c_f9d534d9ac78109a73e10cd906eaece716f4b5ef1ef19f9e9689e5ae3081899b@group.calendar.google.com',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketReperchPickupCalendarId(market: Maybe<string>): string {
  if (!isProduction()) return 'remoovit.com_fc616p776sv70buk3dcdatk7m8@group.calendar.google.com';
  const marketMap: MarketMap<string> = zipObj(
    MARKETS,
    Array<string>(MARKETS.length).fill(
      'remoovit.com_auvokiclttra3gnf09p0hdoipk@group.calendar.google.com',
    ),
  );
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketVideoConsultationLink(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = zipObj(
    MARKETS,
    Array<string>(MARKETS.length).fill(
      'https://us02web.zoom.us/j/85673638078?pwd=jSGa5ztA8gBSC6zh1ZwbE3SuoDdnE1.1',
    ),
  );
  return marketMap[validMarketOrDefault(market)];
}
export function getShopifyLocationId(market: Maybe<string>): number {
  const marketMap: MarketMap<number> = {
    [Market.BAY_AREA]: 49578836,
    [Market.PHOENIX]: 60947759170,
    [Market.MIAMI]: 60956803138,
    [Market.SACRAMENTO]: 65585250370,
    [Market.LOS_ANGELES]: 67393060930,
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketRemoovYelpLink(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = {
    [Market.BAY_AREA]: 'https://www.yelp.com/biz/remoov-south-san-francisco',
    [Market.PHOENIX]: 'https://www.yelp.com/biz/remoov-phoenix',
    [Market.MIAMI]: 'https://www.yelp.com/biz/remoov-no-title',
    [Market.SACRAMENTO]: 'https://www.yelp.com/biz/remoov-sacramento-3',
    [Market.LOS_ANGELES]: 'https://www.yelp.com/biz/remoov-east-los-angeles',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketRemoovFacebookLink(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = zipObj(
    MARKETS,
    Array<string>(MARKETS.length).fill('https://facebook.com/remoovinc/'),
  );
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketRemoovInstagramLink(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = zipObj(
    MARKETS,
    Array<string>(MARKETS.length).fill('https://www.instagram.com/remoovit/'),
  );
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketRemoovTwitterLink(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = zipObj(
    MARKETS,
    Array<string>(MARKETS.length).fill('https://twitter.com/remoovit'),
  );
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketReperchLink(market: Maybe<string>): string {
  const marketMap: ActiveStoreMarketMap<string> = {
    [Market.BAY_AREA]: 'https://reperch.com/bayarea',
    [Market.PHOENIX]: 'https://reperch.com/phoenix',
  };
  const m = market || DEFAULT_MARKET;
  return marketMap[isActiveStoreMarket(m) ? m : DEFAULT_MARKET];
}
export function getMarketReperchFacebookLink(market: Maybe<string>): string {
  const marketMap: ActiveStoreMarketMap<string> = {
    [Market.BAY_AREA]: 'https://www.facebook.com/reperchsf',
    [Market.PHOENIX]: 'https://www.facebook.com/reperchphx',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketReperchInstagramLink(market: Maybe<string>): string {
  const marketMap: ActiveStoreMarketMap<string> = {
    [Market.BAY_AREA]: 'https://www.instagram.com/reperch.sf/',
    [Market.PHOENIX]: 'https://www.instagram.com/reperch.phx/',
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketReperchTwitterLink(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = zipObj(
    MARKETS,
    Array<string>(MARKETS.length).fill('https://twitter.com/reperch_'),
  );
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketReperchPinterestLink(market: Maybe<string>): string {
  const marketMap: MarketMap<string> = zipObj(
    MARKETS,
    Array<string>(MARKETS.length).fill('https://www.pinterest.com/reperch/'),
  );
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketChangeFee(market: Maybe<string>): number {
  const marketMap: MarketMap<number> = zipObj(MARKETS, Array<number>(MARKETS.length).fill(50));
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketCancelationFee(market: Maybe<string>): number {
  const marketMap: MarketMap<number> = zipObj(MARKETS, Array<number>(MARKETS.length).fill(75));
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketPickupPrice(market: Maybe<string>): PickupPriceType {
  return MarketsPickupPrices[validMarketOrDefault(market)];
}
export function getMarketDonationPriceTable(market: Maybe<Market>): ProgressiveFeeTable {
  return getMarketPickupPrice(market).donation;
}
export function getMarketRecyclePriceTable(market: Maybe<Market>): ProgressiveFeeTable {
  return getMarketPickupPrice(market).recycle;
}
export function getMarketByIp({region}: {region: Maybe<string>}): Market {
  if (!region) return DEFAULT_MARKET;
  return ipRegionMap[region] || DEFAULT_MARKET;
}
type Point = {lat: number; lng: number};
const EARTH_RADIUS_IN_MILES = 3963.1906;
export function calculateDistanceInMiles(point2: Point, point1: Point): number {
  const phi1 = point1.lat * (Math.PI / 180);
  const phi2 = point2.lat * (Math.PI / 180);
  const delta_phi = (point2.lat - point1.lat) * (Math.PI / 180);
  const delta_lambda = (point2.lng - point1.lng) * (Math.PI / 180);
  const a =
    Math.sin(delta_phi / 2.0) ** 2 +
    Math.cos(phi1) * Math.cos(phi2) * Math.sin(delta_lambda / 2.0) ** 2;
  return 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * EARTH_RADIUS_IN_MILES;
}
export function getMarketCoordinates(market: Maybe<string>): Point {
  const marketMap: MarketMap<Point> = {
    [Market.BAY_AREA]: {lat: 37.7643533, lng: -122.4104194},
    [Market.PHOENIX]: {lat: 33.4423377, lng: -112.0634784},
    [Market.MIAMI]: {lat: 25.8236722, lng: -80.3206459},
    [Market.SACRAMENTO]: {lat: 38.67105062949881, lng: -121.33021195891286},
    [Market.LOS_ANGELES]: {lat: 34.03376835823268, lng: -118.37886286441773},
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getMarketMapHoleCovers(market: Maybe<string>): GeoJsonFeature[] {
  const marketMap: MarketMap<GeoJsonFeature[]> = {
    [Market.BAY_AREA]: [
      {
        type: 'Feature',
        properties: {
          shape: 'Polygon',
          name: 'Unnamed Layer',
          category: 'default',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
              [-111.82571411132812, 33.57057754966134],
              // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
              [-111.82022094726562, 33.47727218776036],
              [-111.6928482055664, 33.48099488035473],
              [-111.69525146484375, 33.570291490846614],
              // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
              [-111.82571411132812, 33.57057754966134],
            ],
          ],
        },
      },
    ],
    [Market.PHOENIX]: [
      {
        type: 'Feature',
        properties: {
          shape: 'Polygon',
          name: 'Unnamed Layer',
          category: 'default',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [-111.82168006896973, 33.56793146945981],
              [-111.82236671447754, 33.538175365292034],
              [-111.80477142333984, 33.53824690667674],
              [-111.80580139160156, 33.509911888077184],
              [-111.7529296875, 33.50819431588375],
              [-111.75361633300781, 33.49674296343326],
              [-111.78863525390625, 33.49502512990121],
              [-111.78863525390625, 33.48299934087646],
              [-111.72409057617188, 33.482426642603215],
              // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
              [-111.68838500976562, 33.51735430695927],
              [-111.719970703125, 33.55226787486355],
              [-111.70417785644531, 33.57057754966134],
              // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
              [-111.82022094726562, 33.571149664447326],
              [-111.82159423828125, 33.56796722784092],
            ],
          ],
        },
      },
    ],
    [Market.MIAMI]: [] as GeoJsonFeature[],
    [Market.SACRAMENTO]: [] as GeoJsonFeature[],
    [Market.LOS_ANGELES]: [] as GeoJsonFeature[],
  };
  return marketMap[validMarketOrDefault(market)];
}
export function getClosestMarketByCoordinate(point: Point): Market {
  const warehouse = (market: Market) => getMarketCoordinates(market);
  return sortBy(m => calculateDistanceInMiles(point, warehouse(m)), MARKETS)[0];
}
export function validateRescheduleDate(date: Maybe<Timestamp>) {
  if (!date) return true;
  if (differenceInDays(date, isoNow()) < 1) return false;
  return true;
}

type TimezoneOption = {market?: string; tz?: Timezone};
const optionToTimezone = (option: TimezoneOption | undefined): string =>
  option?.tz || marketToTimezone(option?.market) || marketToTimezone(DEFAULT_MARKET);
export function startOfDayTZ(t: Timestamp, options?: TimezoneOption) {
  return Instant.from(t)
    .toZonedDateTimeISO(optionToTimezone(options))
    .startOfDay()
    .toInstant()
    .toString({timeZone: optionToTimezone(options)});
}
export function startOfWeekTZ(t: Timestamp, options?: TimezoneOption) {
  const refDate = Instant.from(t).toZonedDateTimeISO(optionToTimezone(options)).startOfDay();
  return refDate
    .add({days: -refDate.dayOfWeek})
    .toInstant()
    .toString({timeZone: optionToTimezone(options)});
}
export function endOfWeekTZ(t: Timestamp, options?: TimezoneOption) {
  const refDate = Instant.from(t).toZonedDateTimeISO(optionToTimezone(options)).startOfDay();
  return refDate
    .add({days: 7 - refDate.dayOfWeek})
    .add({seconds: -1})
    .toInstant()
    .toString({timeZone: optionToTimezone(options)});
}
export function currentIsoWeek(timestamp: Timestamp): TimestampInterval {
  return {
    startDate: startOfWeekTZ(timestamp),
    endDate: endOfWeekTZ(timestamp),
  };
}
export function updateCron(cron: string, baseMarket: Market, targetMarket: Market): string {
  return adjust(
    2, // hour parameter is the 3rd on cron
    marketHour =>
      // e.g. scheduled for 8am on each market should show 5am for Miami (runs on SF time)
      getHours(
        convertTimestampToTimezone(
          replaceTimezone(
            addHours(startOfToday(), parseInt(marketHour)),
            marketToTimezone(targetMarket),
          ),
          marketToTimezone(baseMarket),
        ),
      ).toString(),
    cron.split(' '),
  ).join(' ');
}
export function marketTagToActiveStoreMarket(marketTag: Maybe<string>): ActiveStoreMarket {
  const storeMarket = MARKET_TAG_MAP[marketTag || DEFAULT_MARKET];
  if (isActiveStoreMarket(storeMarket)) storeMarket;
  return DEFAULT_MARKET;
}
export function marketToMarketTag(market: ActiveMarket): MarketTag {
  return (invertObj(MARKET_TAG_MAP)[market] || 'bayarea') as MarketTag;
}
export function isMarketTag(market: Maybe<string>): market is MarketTag {
  return !!market && !!MARKET_TAG_MAP[market];
}
export function getMarketLink(market: ActiveStoreMarket): string {
  return '/' + marketToMarketTag(market);
}
export function getMarketAlternativeName(market: ActiveStoreMarket): string {
  return market === Market.BAY_AREA ? 'San Francisco' : market;
}
