export const priceTiers = {
  local: 'In-Area',
  extended: 'Extended',
  double: 'Double',
  out_of_area: 'Out of Area',
};
export enum PriceTier {
  LOCAL = 'local',
  EXTENDED = 'extended',
  DOUBLE = 'double',
  OUT_OF_AREA = 'out_of_area',
}
export const IN_AREA_PRICE_TIERS = [PriceTier.LOCAL, PriceTier.EXTENDED, PriceTier.DOUBLE];
const Constants = {
  ENV_VAR: {
    test: {
      REMOOV_URL: 'http://localhost:5001',
      REMOOV_NODE_URL: 'http://localhost:5001',
      S3_URL: 'https://remoov-test.s3.amazonaws.com',
      COOKIE_KEY: '_RemoovTest_session3',
      SENTRY_BROWSER_DSN: '',
      SENTRY_TRACE_SAMPLE_RATE: 1,
      REPERCH_URL: 'http://localhost:3000',
    },
    development: {
      REMOOV_URL: 'http://localhost:5001',
      REMOOV_NODE_URL: 'http://localhost:5001',
      S3_URL: 'https://remoov-development.s3.amazonaws.com',
      COOKIE_KEY: '_RemoovDevelopment_session3',
      SENTRY_BROWSER_DSN: '',
      SENTRY_TRACE_SAMPLE_RATE: 1,
      REPERCH_URL: 'http://localhost:3000',
    },
    staging: {
      REMOOV_URL: 'https://staging.remoovit.com',
      REMOOV_NODE_URL: 'https://stagingapp.remoovit.com',
      S3_URL: 'https://remoov-staging.s3.amazonaws.com',
      COOKIE_KEY: '_RemoovStaging_session3',
      SENTRY_BROWSER_DSN: 'https://fea9637c264e4c3896b4f3536fc3c071@sentry.io/1460708',
      SENTRY_TRACE_SAMPLE_RATE: 1,
      REPERCH_URL: 'https://reperch.com',
    },
    production: {
      REMOOV_URL: 'https://remoovit.com',
      REMOOV_NODE_URL: 'https://app.remoovit.com',
      S3_URL: 'https://remoov-production.s3.amazonaws.com',
      COOKIE_KEY: '_Remoov_session3',
      SENTRY_BROWSER_DSN: 'https://2682e2eee56a4501ab09c110ecf8ad64@sentry.io/1460707',
      SENTRY_TRACE_SAMPLE_RATE: 0.1,
      REPERCH_URL: 'https://reperch.com',
    },
  },
  PaypalClientId: {
    sandbox: 'AcxhPQzJhtVwBKqjSBiMAVZAEgZKoR8q5-oav66AGVN1KaeTvPIoceFfYMDawpbGfmqeIGvxn0pZhLAM',
    production: 'Aa2xH1j9b4AuY03uDugzNEyxTIeyVwziyc6mJbn1UYMr1RrSD64wEq04ndy4AQ1PS19Vl7tNtyHYwPiR',
  },
  PLACEHOLDER_IMAGE_URL:
    'https://s3-us-west-2.amazonaws.com/remoov-production/warehouse-photos/no_photo.jpg',
  PREVIEW_NOT_AVAILABLE_URL:
    'https://s3-us-west-2.amazonaws.com/remoov-production/warehouse-photos/no_preview_available.jpg',
  HELP_REMOOV_REGISTRATION_URL:
    'http://help.remoovit.com/support/solutions/articles/9000098974-remoov-dashboard-registration',
  ApiMessages: {
    INTERNAL_SERVER_ERROR: 'Internal Server Error.',
    INVALID_ACCESS_TOKEN: 'Invalid access token.',
    INVALID_COOKIE: 'You signed out in another tab or window. Please, sign in again.',
    NOT_AUTHORIZED: 'Not authorized.',
    FORBIDDEN: 'Forbidden',
  },
  Url: {
    ROOT_URL: '/',
    SIGN_OUT_URL: '/sign_out',
    OWNER_API_URL: '/owner_api',
    ADMIN_API_URL: '/admin_api',
    SITE_API_URL: '/site_api',
    BLOG_URL: 'https://blog.remoovit.com',
    MMS_QUOTE: '/mms_quote',
    CALL_FORWARDING: '/call_forwarding',
    SCHEDULE_PICKUP_URL: '/schedule_pickup',
    RESCHEDULE_PICKUP_URL: '/reschedule_pickup',
    CANCEL_PICKUP_URL: '/cancel_pickup',
    SCHEDULE_IN_HOME_CONSULTATION_URL: '/in_home_consultation',
    RESCHEDULE_IN_HOME_CONSULTATION_URL: '/reschedule_in_home_consultation',
    CANCEL_IN_HOME_CONSULTATION_URL: '/cancel_in_home_consultation',
    IN_HOME_CONSULATION_SCHEDULED_URL: '/consultation_scheduled',
    PICKUP_SCHEDULED_URL: '/pickup_scheduled',
    TLF_CONTACT_URL: 'https://reperch.com/pages/contact-us',
  },
  NewSiteUrlJs: {
    YOUR_QUOTE_URL: '/your-quote',
    PRICING_URL: '/pricing',
    OUT_OF_AREA_URL: '/out-of-area',
    SERVICE_AREA_URL: '/service-area',
    AMBASSADORS_SIGN_UP_URL: '/ambassadors/sign-up',
    AMBASSADORS_INVITE_URL: '/ambassadors/invite',
    PARTNERS_SIGN_UP_URL: '/partners',
    AVANTI_PARTNERS_SIGN_UP_URL: '/avanti-partners',
    PARTNERS_INVITE_URL: '/partners/invite',
    PARTNERS_RESOURCES: '/partners/resources',
    BOOKING_URL: '/booking',
    SIGN_IN_URL: '/sign-in',
    REGISTER_URL: '/register',
    FORGOT_PASSWORD_URL: '/forgot-password',
    SET_PASSWORD_URL: '/set-password',
    PICKUP_SUBSCRIPTION_URL: '/pickup-subscription',
    ITEM_UPDATE_SUBSCRIPTION_URL: '/item-update-subscription',
    SMS_MOCK_URL: '/sms',
    CALL_MOCK_URL: '/call',
    VETTED_PROFESSIONALS: '/vetted-professionals',
  },
  NewSiteUrl: {
    ROOT_URL: '/',
    PRIVACY_URL: '/privacy',
    AMBASSADORS_URL: '/ambassadors',
    TERMS_URL: '/terms',
    ABOUT_URL: '/about',
    NOTICE_URL: '/notice',
    SERVICE_URL: '/service',
    HOW_URL: '/how',
    PRICING_URL: '/pricing',
    SERVICE_AREA_URL: '/service-area',
    FAQ_URL: '/faq',
    ACI_URL: '/aci',
    OUT_OF_AREA_URL: '/out-of-area',
    NOT_FOUND_URL: '/404',
    OUR_PARTNERS_URL: '/our-partners',
  },
  DashboardUrl: {
    ROOT_NAMESPACE: '/admin',
    PARTNERS_URL: '/admin/partners',
    PICKUPS_URL: '/admin/pickups',
    OWNERS_URL: '/admin/owners',
    MERCHANT_ORDERS_URL: '/admin/merchant_orders',
    MERCHANT_ORDER_ITEMS_URL: '/admin/merchant_order_items',
    MERCHANTS_URL: '/admin/merchants',
    ITEMS_URL: '/admin/items',
    POSTINGS_URL: '/admin/postings',
    POSTED_URL: '/admin/postings/posted',
    TO_REPOST_URL: '/admin/postings/to_repost',
    FOR_REMOVAL_URL: '/admin/postings/for_removal',
    REMOVED_URL: '/admin/postings/removed',
    OWNER_PAYMENTS_URL: '/admin/owner_payments',
    ITEM_ISSUES_URL: '/admin/item_issues',
    FULFILLMENTS_URL: '/admin/fulfillments',
    USERS_URL: '/admin/users',
    EDIT_PROFILE_URL: '/admin/edit_profile',
    CALENDAR_URL: '/admin/calendar',
    LIST_CALENDAR_URL: '/admin/calendar/list',
    PHOTO_EDITOR_QUEUE_URL: '/admin/photo_editor_queue',
    TAGGER_QUEUE_URL: '/admin/tagger_queue',
    CURATOR_QUEUE_URL: '/admin/curator_queue',
    OFFERS_DASHBOARD_URL: '/admin/offers',
    OPERATIONS_DASHBOARD_URL: '/admin/operations',
    OPERATIONS_SCHEDULING_URL: '/admin/operations/scheduling',
    OPERATIONS_CALENDAR_URL: '/admin/operations/calendar',
    OPERATIONS_JOBS_URL: '/admin/operations/jobs',
    OPERATIONS_TRUCKS_URL: '/admin/operations/trucks',
    OPERATIONS_STAFF_URL: '/admin/operations/staff',
    OPERATIONS_DELIVERIES_URL: '/admin/operations/deliveries',
    ZIPCODES_URL: '/admin/zipcodes',
    ADMIN_PENDING_TASKS_URL: '/admin/admin_pending_tasks',
    PENDING_TASKS_URL: '/admin/pending_tasks',
    BOOKING_CONCEPT_URL: '/admin/booking_concept',
  },
  CustomerPortalUrl: {
    DASHBOARD_URL: '/dashboard',
    ITEMS_URL: '/dashboard/items',
    DONATION_ITEMS_URL: '/dashboard/items/donation',
    RECYCLE_ITEMS_URL: '/dashboard/items/recycle',
    EXPIRED_ITEMS_URL: '/dashboard/items/expired',
    FAQ_URL: 'http://help.remoovit.com/support/solutions/folders/9000090736',
  },
  NewCustomerPortalUrl: {
    BETA_DASHBOARD_URL: '/dashboard',
    BETA_ITEMS_URL: '/dashboard/items',
    BETA_AUCTION_ITEMS_URL: '/dashboard/items/auction',
    BETA_DONATION_ITEMS_URL: '/dashboard/items/donation',
    BETA_RECYCLE_ITEMS_URL: '/dashboard/items/recycle',
    BETA_EXPIRED_ITEMS_URL: '/dashboard/items/expired',
  },
  TlfUrl: {
    TLF_HOME_URL: '/tlf',
    TLF_UNSUBSCRIBE_URL: '/unsubscribe',
    TLF_OFFERS_URL: '/offers',
  },
  EndOfConsignmentAction: {
    AUCTION: 'auction',
    DONATE: 'donate',
    RETURN: 'return',
  },
  PickupPaymentType: {
    CREDIT_CARD: 'Credit Card',
    CHECK: 'Check',
    CASH: 'Cash',
    INVOICE: 'Invoice',
    UNPAID: 'Unpaid',
    PAYPAL: 'Paypal',
  },
  PaymentType: {
    CHECK: 'Check',
    PAYPAL: 'Paypal',
    MANUAL: 'Manual',
  },
  ItemCondition: {
    FAIR: 'Fair',
    GOOD: 'Good',
    VERY_GOOD: 'Very Good',
    EXCELLENT: 'Excellent',
  },
  WearableCondition: {
    NEW: 'New',
    LIKE_NEW: 'Like New',
    VERY_GOOD: 'Very Good',
    GOOD: 'Good',
  },
  WearableSize: {
    XS: 'XS',
    S: 'S',
    M: 'M',
    L: 'L',
    XL: 'XL',
  },
  BedSize: {
    TWIN: 'Twin',
    FULL: 'Full',
    QUEEN: 'Queen',
    KING: 'King',
    CAL_KING: 'Cal King',
  },
  Fuel: {
    GAS: 'Gas',
    ELECTRIC: 'Electric',
  },
  FurnitureMaterial: {
    WOOD: 'wood',
    METAL: 'metal',
    GLASS: 'glass',
    LEATHER: 'leather',
    MARBLE_STONE: 'marble/stone',
    PLASTIC: 'plastic',
  },
  SofaMaterial: {
    LEATHER: 'leather',
    PLEATHER: 'pleather',
    MICROFIBER: 'microfiber',
    FABRIC: 'fabric',
  },
  ResaleType: {
    CONSIGNMENT: 'Consignment',
    AUCTION: 'Auction',
    FLASH_SALE: 'Flash',
    DONATION: 'Donation',
    RECYCLE: 'Recycle',
    UNSURE: 'Unsure',
    REJECTED: 'Rejected',
  },
  PickupStatus: {
    RECEIVED_CALL: 'Received Call',
    LEAD: 'To call',
    CALLED_LEAD: 'Called Lead',
    ONLINE_CONSULTATION: 'Online Consultation',
    CONSULTATION_SCHEDULED: 'Consultation Scheduled',
    CONSULTATION_COMPLETED: 'Consultation Completed',
    VIDEO_CONSULTATION_SCHEDULED: 'Video Consultation Scheduled',
    VIDEO_CONSULTATION_COMPLETED: 'Video Consultation Completed',
    PICKUP_QUOTE_PREPARED: 'Pickup Quote Prepared',
    PICKUP_QUOTE_SENT: 'Pickup Quote Sent',
    PICKUP_SCHEDULED: 'Pickup Scheduled',
    PICKUP_COMPLETED: 'Pickup Completed',
    PICKUP_INVOICED: 'Pickup Invoiced',
    PICKUP_PAID: 'Pickup Paid',
    PICKUP_UNPAID: 'Pickup Unpaid',
    CLOSED: 'Closed',
    NOT_GOOD: 'Not Good',
    LOST: 'Lost',
    OUT_OF_AREA: 'Out of Area',
  },
  PickupSize: {
    '0/1': 0,
    '1/8': 0.125,
    '1/4': 0.25,
    '3/8': 0.375,
    '1/2': 0.5,
    '5/8': 0.625,
    '3/4': 0.75,
    '7/8': 0.875,
    Full: 1,
  },
  FulfillType: {
    PICKUP: 'Pickup',
    DELIVERY: 'Delivery',
    SHIPPING: 'Shipping',
  },
  FulfillmentStatus: {
    DONE: 'Done',
    READY: 'Ready',
    PENDING: 'Pending',
  },
  Ability: {
    ADMIN: 'Admin',
    DRIVER_LEAD: 'Driver Lead',
    DRIVER: 'Driver',
    ESTIMATOR: 'Estimator',
    PHOTO_EDITOR: 'Photo Editor',
    CURATOR: 'Curator',
    TAGGER: 'Tagger',
    POSTER: 'Poster',
    WAREHOUSE: 'Warehouse',
    PHOTOGRAPHER: 'Photographer',
    CUSTOMER_SERVICE: 'Customer Service',
    EMPLOYEE: 'Employee',
    OWNER: 'Owner',
    WORKER: 'Worker',
    ITEM_SEARCH: 'Item Search',
    CASH_OUT: 'cashOut',
    CANCEL_OWNER_PAYMENT: 'cancelOwnerPayment',
    BOOKING_MANAGER: 'Booking Manager',
  },
  PickupEventType: {
    PICKUP_CREATED: 'Pickup Created',
    PICKUP_UPDATED: 'Pickup Updated',
    ONLINE_CONSULTATION: 'Online Consultation',
    SMS_CONSULTATION: 'SMS Consultation',
    IN_HOME_CONSULTATION: 'In-home Consultation',
    CUSTOMER_CASHOUT: 'Customer Cashout',
    CONSULTATION_SCHEDULED: 'Consultation Scheduled',
    CONSULTATION_RESCHEDULED: 'Consultation Rescheduled',
    CONSULTATION_CANCELLED: 'Consultation Cancelled',
    PICKUP_SCHEDULED: 'Pickup Scheduled',
    PICKUP_RESCHEDULED: 'Pickup Rescheduled',
    PICKUP_CANCELLED: 'Pickup Cancelled',
    NOTES_ADDED: 'Notes Added',
    PICKUP_COMPLETED: 'Pickup Completed',
    ESTIMATE_NOTES_ADDED: 'Estimate Notes Added',
    REQUESTED_REASSESS: 'Requested Estimate Reassess',
    QUESTIONS_SENT: 'Questions Sent',
    QUESTIONS_ANSWERED: 'Questions Answered',
    STATUS_CHANGE: 'Status Change',
    DRIVER_REQUESTED_REASSESSMENT: 'requestItemReassessment',
    DRIVER_REASSESSMENT_ANSWER: 'answerItemReassessment',
    ACCEPTED_QUOTE: 'customerAcceptedQuote',
  },
  PickupCommunicationMethod: {
    SMS: 'sms',
    EMAIL: 'email',
  },
  StorageUnitSize: {
    Size5x5: '5x5',
    Size5x10: '5x10',
    Size5x15: '5x15',
    Size10x10: '10x10',
    Size10x15: '10x15',
    Size10x20: '10x20',
    'Size10x25+': '10x25+',
    SizeOther: 'Other',
  },
  StorageUnitPercentage: {
    P0: '0%',
    P20: '20%',
    P40: '40%',
    P60: '60%',
    P80: '80%',
    P100: '100%',
  },
  ItemIssueType: {
    NEEDS_PHOTOS: 'Needs Photos',
    WRONG_CATEGORY: 'Wrong Category',
    OTHER: 'Other',
    CHANGE_RESALE_TYPE: 'Wrong Resale Type',
    ADMIN_OTHER: 'Admin Other',
  },
  ItemIssueWarehouseType: {
    NEEDS_PHOTOS: 'Needs Photos',
    OTHER: 'Other',
  },
  Functioning: {
    UNTESTED: 'Untested',
    WORKING: 'Working',
    NOT_WORKING: 'Not Working',
  },
  GoodwillCategory: {
    ACCESSORIES: 'Accesories',
    OTHER: 'Other',
    BOOKS: 'Books',
    LAPTOPS_TABLETS: 'Laptops & Tablets',
    MONITORS: 'Monitors',
    TELEVISIONS: 'Televisions',
    CONSUMER_ELECTRONICS: 'Consumer Electronics',
    FURNITURE: 'Furniture',
    HOUSEWARE: 'Houseware',
    SHOES: 'Shoes',
    CLOTHING: 'Clothing',
  },
  InventoryAction: {
    PICK: 'Pick',
    PLACE: 'Place',
  },
  ItemStatus: {
    DONATED: 'Donated',
    RECYCLED: 'Recycled',
    AUCTIONING: 'Auctioning',
    AUCTIONED: 'Auctioned',
    SOLD: 'Sold',
    PAID: 'Paid',
    CLAIMED: 'Claimed',
    RETURNED: 'Returned',
    STORED: 'Stored',
  },
  MerchantSource: ['Listing', 'Referral', 'Advertising', 'Direct', 'eBay', 'In Bound', 'Shopify'],
  MerchantStatus: {
    ACTIVE: 'Active',
    PENDING_CONTRACT: 'Pending Contract',
    NEGOTIATING: 'Negotiating',
    CONTRACT_SIGNED: 'Contract Signed',
    INACTIVE: 'Inactive',
    DELINQUENT: 'Delinquent',
    ON_HOLD: 'On Hold',
  },
  MerchantType: {
    DIRECT: 'Direct',
    MARKETPLACE: 'Marketplace',
    CONSIGNMENT: 'Consignment',
    AUCTION: 'Auction',
    DONATION: 'Donation',
    DISCARD: 'Discard',
    RETURN: 'Return',
  },
  ResellingInfo: {
    CONSIGN: 'Consign',
    PURCHASE: 'Purchase',
    LEAD_GEN: 'Lead-Gen',
  },
  ShopifyRequestType: {
    ORDER: 'order',
    REFUND: 'refund',
    FULFILLMENT: 'fulfillment',
  },
  TransportType: {
    PICK_UP: 'Pick Up',
    DROP_OFF: 'Drop Off',
  },
  TruckType: {
    CAR: 'Car',
    CARGO_VAN_PICKUP_TRUCK: 'Cargo Van / Pickup Truck',
    BOX_TRUCK_16: "Box Truck (16')",
    BOX_TRUCK_20: "Box Truck (20')",
    BOX_TRUCK_26: "Box Truck (26')",
    OTHER: 'Other',
  },
  WarehouseItemTag: {
    NEEDS_REPAIR: 'needs repair',
    MISSING_PARTS: 'missing parts',
    MISSING_CABLES: 'missing cables',
    PARTICLE_BOARD: 'particle board',
    FRAMED: 'framed',
    SHIPPABLE: 'shippable',
    LARGE: 'large',
    BROKEN: 'broken',
    DONATION: 'donation',
    CONSIGNMENT: 'consignment',
    AUCTION: 'auction',
    DISPOSAL: 'disposal',
    LOT_SALE: 'lot sale',
    REQUIRES_CRATING: 'requires crating',
  },
  BucketTag: {
    FREE_TAG: 'free',
    DONATION_TAG: 'donation',
    DISPOSAL_TAG: 'disposal',
    AUCTION_TAG: 'auction',
    LIQUIDATION_TAG: 'liquidation',
  },
  PickupRoomType: {
    BD1: '1 Bedroom or Less',
    BD2: '2 Bedroom',
    BD3: '3 Bedroom or More',
    OFFICE: 'Office',
    STORAGE_UNIT: 'Storage Unit',
  },
  LogisticTag: {
    NAVIGATION: 'navigation',
    STAIRS: 'stairs',
    PARKING: 'parking',
    COI: 'coi',
    OTHER: 'other',
  },
  ResaleTypeReason: {
    MISSING_PIECES: 'The item is missing pieces',
    DAMAGED: 'The item is damaged / not in sellable condition',
    STYLE: 'The item style is not one that currently has a resale market',
    GOOD_CONDITION: 'The item is in good condition',
    OTHER: 'Other',
  },
  State: [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ],
  OutboundLocation: {
    OFFICE: 'Office',
    DOCK: 'Dock',
  },
  DonationCategory: {
    appliances: 'Appliances',
    art: 'Art',
    books: 'Books',
    clothing: 'Clothing',
    electronics: 'Electronics',
    furniture: 'Furniture',
    home_decor: 'Home & Decor',
    kitchen: 'Kitchen',
    sports_outdoors: 'Sports & Outdoors',
    other: 'Other',
  },
  BusinessLogic: {
    DAYS_TO_SHOW_IN_UNLOAD: 4,
    DAYS_OVERDUE_TO_PROCESS: 14,
  },
  inprocessingCategoryTags: [
    {
      section: 'Material',
      categories: [
        'Furniture > Chairs > Dining Chairs',
        'Furniture > Chairs > Stools, Ottomans & Benches',
        'Furniture > Chairs > Lounge',
        'Furniture > Chairs > Lounge',
        'Furniture > Other',
        'Furniture > Sofas > Sectional',
        'Furniture > Sofas > Sleeper Sofa',
        'Furniture > Sofas > 3-seat',
        'Furniture > Sofas > Loveseat',
        'Furniture > Sofas > Other',
        'Furniture > Tables > Coffee Table',
        'Furniture > Office > Desks',
        'Furniture > Office > Chairs',
        'Furniture > Office > Other',
        'Furniture > Outdoor > Chairs',
        'Furniture > Outdoor > Sets',
        'Furniture > Outdoor > Other',
      ],
      tags: [
        'wood',
        'veneer',
        'metal',
        'plastic',
        'glass',
        'marble',
        'faux marble/stone',
        'leather',
        'pleather',
        'fabric',
      ],
    },
    {
      section: 'Material',
      categories: [
        'Furniture > Beds > Frame',
        'Furniture > Beds > Frame & Mattress',
        'Furniture > Beds > Mattress',
        'Furniture > Bookshelves',
        'Furniture > Dressers',
        'Furniture > Storage > Cabinet',
        'Furniture > Storage > Entertainment Center',
        'Furniture > Storage > Hutch & Armoire',
        'Furniture > Storage > Other',
        'Furniture > Storage > Trunk',
        'Furniture > Storage > Sideboard & Credenza',
        'Furniture > Tables > Dining Set',
        'Furniture > Tables > Dining Table',
        'Furniture > Tables > End Table',
        'Furniture > Tables > Other',
        'Furniture > Tables > Side & Console Table',
        'Furniture > Office > Cabinets',
        'Furniture > Office > Tables',
        'Furniture > Outdoor > Tables',
      ],
      tags: ['wood', 'veneer', 'metal', 'plastic', 'glass', 'marble', 'faux marble/stone'],
    },
    {
      section: 'Material',
      categories: [
        'Home & Decor > Art > Sculpture',
        'Home & Decor > Vases',
        'Kitchen > Glassware',
        'Kitchen > Dishware',
        'Kitchen > Cutlery',
      ],
      tags: ['crystal', 'metal', 'wood', 'plastic', 'porcelain', 'silver'],
    },
  ],
  taggerCategoryTags: [
    {
      section: 'Location',
      categories: ['Furniture'],
      tags: ['bathroom', 'dining room', 'kitchen', 'living room', 'office', 'pation', 'storage'],
    },
  ],
  curatorCategoryTags: [
    {
      section: 'General',
      categories: ['Apparel'],
      tags: ['custom', 'designer', 'fashion', 'hipster', 'mens', 'retro', 'womens'],
    },
    {
      section: 'General',
      categories: ['Appliances'],
      tags: ['commercial', 'garage', 'industrial', 'kitchen', 'laundry', 'office'],
    },
    {
      section: 'General',
      categories: ['Electronics & Tools'],
      tags: [
        'apple',
        'blue-ray',
        'bose',
        'car audio',
        'desktop',
        'dj',
        'dvd',
        'hifi',
        'home audio',
        'keyboard',
        'laptop',
        'lighting',
        'musician',
        'professional',
        'recording',
        'stereo',
        'studio',
      ],
    },
    {
      section: 'Descriptor',
      categories: ['Furniture'],
      tags: ['children', 'custom-made', 'diy project', 'folding', 'ikea', 'unfinished'],
    },
    {
      section: 'Style',
      categories: ['Furniture'],
      tags: [
        '1940s',
        '1950s',
        '1960s',
        '1970s',
        '1980s',
        'african',
        'american',
        'art deco',
        'atomic age',
        'bauhaus',
        'bruched metal',
        'chinese',
        'classic',
        'coastal',
        'contemporary',
        'country',
        'craftman',
        'credenza',
        'designer',
        'eames',
        'euro',
        'funky',
        'heirloom',
        'industrial',
        'japanese',
        'latin',
        'mid century modern',
        'modern',
        'pre-war',
        'retro',
        'scandinavian',
        'shabby chic',
        'traditional',
        'transitional',
        'victorian',
        'western',
      ],
    },
    {
      section: 'Material',
      categories: ['Furniture'],
      tags: [
        'aluminum',
        'ash',
        'bamboo',
        'birch',
        'cherry',
        'copper',
        'formica',
        'glass',
        'gold',
        'granite',
        'hardwood',
        'laminate',
        'leather',
        'mahogany',
        'maple',
        'marble',
        'metal',
        'oak',
        'wood',
        'particle board',
        'pine',
        'plastic',
        'plywood',
        'poplar',
        'rosewood',
        'silver',
        'teak',
        'veneer',
        'walnut',
        'wicker',
        'zebra',
      ],
    },
    {
      section: 'General',
      categories: ['Home & Decor'],
      tags: [
        'DIY',
        'art deco',
        'fine china',
        'floor deco',
        'glass',
        'lighting',
        'wall deco',
        'wood',
      ],
    },
    {
      section: 'Details',
      categories: ['Home & Decor > Art'],
      tags: [
        '3d',
        'abstract',
        'canvas',
        'copy',
        'drawing',
        'framed',
        'lithograph',
        'original',
        'photography',
        'portrait',
        'poster',
        'sculture',
      ],
    },
    {
      section: 'Style',
      categories: ['Home & Decor > Art'],
      tags: [
        'african',
        'american',
        'baroque',
        'chinese',
        'contemporary',
        'cubism',
        'deceased',
        'euro',
        'impressionism',
        'japanese',
        'landscape',
        'latin',
        'modern',
        'religious',
        'renaissance',
        'rococo',
        'still life',
        'surrealism',
        'western',
      ],
    },
    {
      section: 'Material',
      categories: ['Home & Decor > Art'],
      tags: ['acrylic', 'oil', 'print', 'water color'],
    },
    {
      section: 'General',
      categories: ['Kitchen'],
      tags: ['crystal', 'grill', 'silver'],
    },
    {
      section: 'General',
      categories: ['Other'],
      tags: [
        'advertising',
        'cat',
        'dog',
        'drum',
        'entertainment',
        'gaming',
        'guitar',
        'persian',
        'rare',
        'sports',
        'vinyl',
      ],
    },
    {
      section: 'General',
      categories: ['Sports & Outdoors'],
      tags: ['ball sports', 'cycling', 'home', 'outdoors', 'raquet sports', 'water', 'winter'],
    },
  ],
  WebflowHostUrl: 'https://remoov-redesign.webflow.io/',
} as const;
export type ConstantsType = typeof Constants;
export default Constants;

export type ResaleType = ConstantsType['ResaleType'][keyof ConstantsType['ResaleType']];
export function isValidResaleType(resale_type: Maybe<string>): resale_type is ResaleType {
  if (!resale_type) return false;
  return Object.values(Constants.ResaleType).includes(resale_type);
}
