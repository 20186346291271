import {Constants} from '@remoov/functional';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {HomePage, OfferPage, UnsubscribePage} from './Pages';
import './Styles';

const {
  TlfUrl: {TLF_HOME_URL, TLF_OFFERS_URL, TLF_UNSUBSCRIBE_URL},
} = Constants;
export function Router() {
  scrollTop();
  return (
    <div>
      <Switch>
        <Route exact path='/' component={HomePage} />
        <Route exact path={TLF_HOME_URL} component={HomePage} />
        <Route exact path={TLF_UNSUBSCRIBE_URL} component={UnsubscribePage} />
        <Route exact path={TLF_HOME_URL + TLF_UNSUBSCRIBE_URL} component={UnsubscribePage} />
        <Route exact path={TLF_OFFERS_URL + '/:id'} component={OfferPage} />
        <Route exact path={TLF_HOME_URL + TLF_OFFERS_URL + '/:id'} component={OfferPage} />
      </Switch>
    </div>
  );
}
function scrollTop() {
  typeof window !== 'undefined' && window.scrollTo(0, 0);
}
