export type Option<Value> = {label: string; value: Value};
export type Options<Value> = Option<Value>[];

export function arrayToOptions<Value>(array: readonly Value[]): Options<Value> {
  return array.map(item => ({label: String(item), value: item}));
}
export function arrayTupleToOptions<Value>(array: [string, Value][]): Options<Value> {
  return array.map(([label, value]) => ({label, value}));
}
export function objectValuesToOptions<Value>(
  object: Record<string, Value> | readonly Value[],
): Options<Value> {
  const values = Object.values(object);
  return arrayToOptions(values);
}
export function objectToOptions<Value>(object: {[key: string]: Value}): Options<Value> {
  return Object.entries(object).map(([label, value]) => ({label, value}));
}
